import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2178b796"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "grid-container" }
const _hoisted_2 = { class: "value-container" }
const _hoisted_3 = {
  key: 0,
  class: "dropdown-arrow"
}
const _hoisted_4 = { class: "dropdown-options" }
const _hoisted_5 = ["onMouseenter", "onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ScrollBox = _resolveComponent("ScrollBox")!
  const _component_FocusBox = _resolveComponent("FocusBox")!

  return (_openBlock(), _createBlock(_component_FocusBox, {
    class: _normalizeClass(['enum-field-control', { disabled: !_ctx.isEditable, open: _ctx.showMenu }]),
    onFocus: _ctx.openMenu,
    onUnfocus: _ctx.closeMenu
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", {
            class: _normalizeClass(['value-text', { 'is-null': _ctx.isNull }])
          }, _toDisplayString(_ctx._property.toString()), 3),
          (_ctx.isEditable)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, "▼"))
            : _createCommentVNode("", true)
        ]),
        (_ctx.showMenu)
          ? (_openBlock(), _createBlock(_component_ScrollBox, {
              key: 0,
              propagateScroll: false,
              style: _normalizeStyle(_ctx.style)
            }, {
              default: _withCtx(() => [
                _createElementVNode("ul", _hoisted_4, [
                  _createElementVNode("li", {
                    class: _normalizeClass([{ active: _ctx.hovered === 'null' }, 'null']),
                    onMouseenter: _cache[0] || (_cache[0] = ($event: any) => (_ctx.hovered = 'null')),
                    onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.updateProperty(null)), ["stop"]))
                  }, " Null ", 34),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, ([k, v]) => {
                    return (_openBlock(), _createElementBlock("li", {
                      key: k,
                      class: _normalizeClass({ active: _ctx.hovered === k }),
                      onMouseenter: ($event: any) => (_ctx.hovered = k),
                      onClick: _withModifiers(($event: any) => (_ctx.updateProperty(k)), ["stop"])
                    }, _toDisplayString(v.toString()), 43, _hoisted_5))
                  }), 128))
                ])
              ]),
              _: 1
            }, 8, ["style"]))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }, 8, ["class", "onFocus", "onUnfocus"]))
}