
import * as Page from "@/store/Commands/PageCommands";
import * as Store from "@/store/StoreTypes";
// Dependencies
import { defineComponent } from "vue";
import { mapGetters, mapMutations, mapState } from "vuex";
// Components
import ScrollBox from "@/components/Containers/ScrollBox.vue";

export default defineComponent({
  name: "ValidatorProblems",
  computed: {

    /**
     * Application Store data
     */
    ...mapState("ApplicationStore", {
      ctx(state: Store.ApplicationStore): Store.ApplicationStore {
        return state;
      },
      editor(state: Store.ApplicationStore): Store.PageEditor {
        return state.activePage;
      }
    }),

    ...mapGetters("ApplicationStore", [
        "getValidationErrors", "getValidationWarnings"
    ])
  
  },
  methods: {

    /**
     * Application Store actions
     */
    ...mapMutations("ApplicationStore", ["execute"]),

    /**
     * Focuses the camera on an object.
     * @param id
     *  The id of the object.
     */
    focus(id: string) {
      let obj = this.editor.page.lookup(id);
      if(obj === this.editor.page) {
        this.execute(new Page.UnselectDescendants(this.editor.page));
      } else if(obj) {
        this.execute(new Page.UnselectDescendants(this.editor.page));
        this.execute(new Page.SelectObject(obj));
        this.execute(new Page.MoveCameraToSelection(this.ctx, this.editor.page))
      }
    }

  },
  components: { ScrollBox }
});
