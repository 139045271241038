import { resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, normalizeStyle as _normalizeStyle, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ContextMenuListing = _resolveComponent("ContextMenuListing")!
  const _component_FocusBox = _resolveComponent("FocusBox")!

  return (_openBlock(), _createBlock(_component_FocusBox, {
    style: _normalizeStyle(_ctx.offset),
    class: "context-menu-control",
    onUnfocus: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('unfocus'))),
    onContextmenu: _cache[2] || (_cache[2] = _withModifiers(() => {}, ["prevent"]))
  }, {
    default: _withCtx(() => [
      _createVNode(_component_ContextMenuListing, {
        sections: _ctx.sections,
        onSelect: _cache[0] || (_cache[0] = data => _ctx.$emit('select', data)),
        forceInsideWindow: false
      }, null, 8, ["sections"])
    ]),
    _: 1
  }, 8, ["style"]))
}