
import { defineComponent, markRaw, PropType, ref } from "vue";
import { clamp, NumberProperty, PropertyType } from "@/assets/scripts/BlockDiagram";

export default defineComponent({
  name: "NumberField",
  setup() {
    return { field: ref<HTMLInputElement | null>(null) };
  },
  props: {
    property: {
      type: Object as PropType<NumberProperty>,
      required: true
    }
  },
  data() {
    return {
      value: "",
      activeProperty: markRaw(this.property)
    }
  },
  computed: {

    /**
     * A reactive version of the property.
     * @returns
     *  The property.
     */
    _property(): NumberProperty {
      let trigger = this.activeProperty.trigger.value;
      return trigger ? this.activeProperty : this.activeProperty; 
    },
    
    /**
     * Tests if the property is editable.
     * @returns
     *  True if the property is editable, false otherwise. 
     */
    isEditable(): boolean {
      return this._property.descriptor.is_editable ?? true;
    }

  },
  methods: {

    /**
     * Field focus behavior.
     */
    onFocus() {
      this.$nextTick(() => {
        this.field!.focus();
      });
    },

    /**
     * Field input behavior.
     */
    onInput() {
      if(this.value === "") {
        this.updateProperty(0);
      }
    },

    /**
     * Field blur behavior.
     */
    onBlur() {
      this.updateProperty(0);
    },

    /**
     * Field keydown behavior.
     * @param event
     *  The keydown event.
     */
    onKeyDown(event: KeyboardEvent) {
      switch(event.key) {
        case "ArrowUp":
          event.preventDefault();
          this.updateProperty(+1);
          break;
        case "ArrowDown":
          event.preventDefault();
          this.updateProperty(-1);
          break;
      }
    },

    /**
     * Updates the field's property value.
     * @param delta
     *  The amount to add to the parsed value.
     *  (Default: 0)
     */
    updateProperty(delta: number = 0) {
      let value;
      if(this.value === "" && delta === 0) {
        // Parse null
        value = null;
      } else {
        // Parse value
        value = parseFloat(this.value);
        if(Number.isNaN(value)) {
          value = 0;
        } else {
          value += delta;
        }
        // Bound value
        let { min, max } = this._property;
        value = clamp(value, min, max); 
        // Bound type
        if(this._property.type === PropertyType.Int) {
          value = Math.round(value);
        }
      }
      if(this._property.toRawValue() !== value) {
        // Update value
        this.$emit("change", this._property, value);
      } else {
        // Refresh value
        this.refreshValue();
      }  
    },

    /**
     * Updates the field's text value.
     */
    refreshValue() {
      this.value = `${ this._property.toRawValue() ?? "" }`
    }
    
  },
  emits: ["change"],
  watch: {
    "property"() {
      this.updateProperty();
      this.activeProperty = markRaw(this.property);
      this.refreshValue();
    },
    "_property.trigger.value"() {
      this.refreshValue();
    }
  },
  mounted() {
    this.refreshValue();
  },
  unmounted() {
    this.updateProperty();
  }
});
