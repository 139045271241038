import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PropertyEditor = _resolveComponent("PropertyEditor")!
  const _component_AccordionPane = _resolveComponent("AccordionPane")!
  const _component_ValidatorProblems = _resolveComponent("ValidatorProblems")!
  const _component_AccordionBox = _resolveComponent("AccordionBox")!

  return (_openBlock(), _createBlock(_component_AccordionBox, { class: "editor-tabs-element" }, {
    default: _withCtx(() => [
      _createVNode(_component_AccordionPane, {
        name: "Properties",
        units: 3
      }, {
        default: _withCtx(() => [
          _createVNode(_component_PropertyEditor, {
            class: "properties-pane",
            property: _ctx.selected
          }, {
            "no-props": _withCtx(() => [
              _createTextVNode(" The selected object has no properties. ")
            ]),
            "no-prop": _withCtx(() => [
              _createTextVNode(" Select a single object to edit its properties. ")
            ]),
            _: 1
          }, 8, ["property"])
        ]),
        _: 1
      }),
      _createVNode(_component_AccordionPane, {
        name: "Problems",
        units: 1
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ValidatorProblems, { class: "validator-problems-pane" })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}