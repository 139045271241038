
import { defineComponent } from "vue";
export default defineComponent({ 
  name: "Plus",
  props: {
    color: {
      type: String,
      default: "#737373"
    }
  }
});
