
// Dependencies
import { EnumProperty, Property } from "@/assets/scripts/BlockDiagram";
import { defineComponent, PropType } from "vue";
// Components
import FocusBox from "@/components/Containers/FocusBox.vue";
import ScrollBox from "@/components/Containers/ScrollBox.vue";

export default defineComponent({
  name: "EnumField",
  props: {
    property: {
      type: Object as PropType<EnumProperty>,
      required: true
    },
    maxHeight: {
      type: Number,
      default: 200
    }
  },
  data() {
    return {
      hovered: "",
      showMenu: false
    }
  },
  computed: {

    /**
     * A reactive version of the property.
     * @returns
     *  The property.
     */
    _property(): EnumProperty {
      let trigger = this.property.trigger.value;
      return trigger ? this.property : this.property; 
    },

    /**
     * Tests if the property is editable.
     * @returns
     *  True if the property is editable, false otherwise. 
     */
    isEditable(): boolean {
      return this._property.descriptor.is_editable ?? true;
    },

    /**
     * Returns the property's set of options.
     * @returns
     *  The property's set of options.
     */
    options(): Map<string, Property> {
      return this._property.options.value;
    },

    /**
     * Tests if the property's value is null.
     * @returns
     *  True if the property's value is null, false otherwise.
     */
    isNull(): boolean {
      return this._property.toRawValue() === null;
    },

    /**
     * Returns the scrollbox's style.
     * @returns
     *  The scrollbox's style.
     */
    style(): { maxHeight: string } {
      return { maxHeight: `${ this.maxHeight }px` };
    }

  },
  methods: {
    
    /**
     * Opens the options menu.
     */
    openMenu() {
      if(!this.isEditable) {
        return;
      }
      this.showMenu = true;      
    },

    /**
     * Closes the options menu.
     */
    closeMenu() {
      // Close menu
      this.showMenu = false;
      // Refresh value
      this.refreshValue();
    },

    /**
     * Updates the field's property value.
     * @param value
     *  The property's new value.
     */
    updateProperty(value: string | null) {
      if(this._property.toRawValue() !== value) {
        // Update property
        this.$emit("change", this._property, value);
      } else {
        // Refresh value
        this.refreshValue();
      }
      // Close menu
      this.showMenu = false;
    },

    /**
     * Updates the field's text value.
     */
    refreshValue() {
      this.hovered = this._property.toRawValue() ?? "null"
    }
    
  },
  emits: ["change"],
  watch: {
    "_property.trigger.value"() {
      this.refreshValue();
    }
  },
  mounted() {
    this.refreshValue();
  },
  components: { FocusBox, ScrollBox }
});
