import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withModifiers as _withModifiers, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-63eba18e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "icon" }
const _hoisted_2 = ["onMouseenter", "onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ContextMenuListing = _resolveComponent("ContextMenuListing")!
  const _component_FocusBox = _resolveComponent("FocusBox")!

  return (_openBlock(), _createBlock(_component_FocusBox, {
    class: "title-bar-control",
    onUnfocus: _ctx.menuClose
  }, {
    default: _withCtx(() => [
      _createElementVNode("li", _hoisted_1, [
        _renderSlot(_ctx.$slots, "icon", {}, undefined, true)
      ]),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menus, (menu) => {
        return (_openBlock(), _createElementBlock("li", {
          key: menu.text,
          class: _normalizeClass({ focused: menu.text === _ctx.focusedMenu }),
          onMouseenter: ($event: any) => (_ctx.menuEnter(menu.text)),
          onClick: ($event: any) => (_ctx.menuOpen(menu.text))
        }, [
          _createElementVNode("p", null, _toDisplayString(menu.text), 1),
          (menu.text === _ctx.focusedMenu)
            ? (_openBlock(), _createBlock(_component_ContextMenuListing, {
                key: 0,
                class: "menu-listing",
                sections: menu.sections,
                onSelect: _ctx.menuSelect,
                onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"]))
              }, null, 8, ["sections", "onSelect"]))
            : _createCommentVNode("", true)
        ], 42, _hoisted_2))
      }), 128))
    ]),
    _: 3
  }, 8, ["onUnfocus"]))
}