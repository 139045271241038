
// Dependencies
import { ListProperty, PropertyType } from "@/assets/scripts/BlockDiagram";
import { defineAsyncComponent, defineComponent, PropType } from "vue";
// Components
import Plus from "@/components/Icons/Plus.vue";
import TextField from "./TextField.vue";
import EnumField from "./EnumField.vue";
import NumberField from "./NumberField.vue";
import DateTimeField from "./DateTimeField.vue";
const DictionaryField = defineAsyncComponent(() => import("./DictionaryField.vue")) as any;

export default defineComponent({
  name: "ListField",
  props: {
    property: {
      type: Object as PropType<ListProperty>,
      required: true
    }
  },
  computed: {

    /**
     * A reactive version of the property.
     * @returns
     *  The property.
     */
    _property(): ListProperty {
      let trigger = this.property.trigger.value;
      return trigger ? this.property : this.property; 
    },

    /**
     * Tests if the property is editable.
     * @returns
     *  True if the property is editable, false otherwise. 
     */
    isEditable(): boolean {
      return this._property.descriptor.is_editable ?? true;
    }

  },
  methods: {
   
    /**
     * Returns a field's component type.
     * @param type
     *  The type of field.
     * @returns
     *  The field's component type.
     */
    getField(type: PropertyType): string | undefined {
      switch(type) {
        case PropertyType.Int:
        case PropertyType.Float:
          return "NumberField";
        case PropertyType.String:
          return "TextField";
        case PropertyType.Date:
          return "DateTimeField";
        case PropertyType.Enum:
          return "EnumField";
        case PropertyType.List:
          return "ListField";
        case PropertyType.Dictionary:
          return "DictionaryField";
      }
    },

    /**
     * Create subproperty behavior.
     */
    onCreate() {
      this.$emit("create", this._property);
    },

    /**
     * Delete subproperty behavior.
     * @param id
     *  The subproperty's id.
     */
    onDelete(id: string) {
      this.$emit("delete", this._property, id);
    }

  },
  emits: ["change", "create", "delete"],
  components: {
    Plus,
    TextField,
    EnumField,
    NumberField,
    DateTimeField,
    DictionaryField
  }
});
