
import { defineComponent, PropType } from 'vue';
import { ContextMenu, ContextMenuItem, ContextMenuSection, MenuType } from "@/assets/scripts/ContextMenuTypes";

const KeyToText: { [key: string]: string } = {
  Control    : "Ctrl",
  Escape     : "Esc",
  ArrowLeft  : "←",
  ArrowUp    : "↑",
  ArrowRight : "→",
  ArrowDown  : "↓",
  Delete     : "Del"
}

export default defineComponent({
  name: 'ContextMenuListing',
  props: {
    sections: {
      type: Array as PropType<ContextMenuSection[]>,
      required: true
    },
    forceInsideWindow: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      xOffset: 0,
      yOffset: 0,
      leaveTimeout: 500,
      leaveTimeoutId: 0,
      focusedSubMenu: null as string | null,
      MenuType
    }
  },
  computed: {

    /**
     * Returns the ContextMenuListing's offset styling.
     * @returns
     *  The ContextMenuListing's offset styling.
     */
    offset(): { marginTop: string, marginLeft: string } {
      return {
        marginTop: `${ this.yOffset }px`,
        marginLeft: `${ this.xOffset }px`
      }
    }

  },
  methods: {
    
    /**
     * Submenu mouse enter behavior.
     * @param item
     *  The hovered submenu item.
     */
    submenuEnter(item: ContextMenu) {
      clearTimeout(this.leaveTimeoutId);
      if(!item.disabled) {
        this.focusedSubMenu = item.text;
      }
    },

    /**
     * Submenu mouse leave behavior.
     */
    submenuLeave() {
      this.leaveTimeoutId = setTimeout(() => {
        this.focusedSubMenu = null;
      }, this.leaveTimeout)
    },

    /**
     * Menu item selection behavior.
     * @param item
     *  The selected menu item.
     */
    onItemClick(item: ContextMenuItem) {
      if(item.disabled)
        return;
      this.$emit("select", item.data, !item.keepMenuOpenOnSelect);
    },

    /**
     * Submenu item selection behavior.
     * @param data
     *  The menu item's data.
     * @param closeSubmenu
     *  If the active submenu should close.
     */
    onChildItemSelect(data: any, closeSubmenu: boolean) {
      this.$emit("select", data, closeSubmenu);
      if(closeSubmenu) {
        this.focusedSubMenu = null;
      }
    },

    /**
     * Formats a keyboard shortcut.
     * @param shortcut
     *  The keyboard shortcut to format.
     * @returns
     *  The formatted keyboard shortcut.
     */
    formatShortcut(shortcut?: string): string | undefined {
      if(!shortcut) {
        return shortcut;
      } else {
        return shortcut
          .split("+")
          .map(c => c in KeyToText ? KeyToText[c] : c)
          .join("+");
      }
    }

  },
  emits: ["select"],
  mounted() {
    if(!this.forceInsideWindow) return;
    // Offset submenu if outside of viewport
    let viewWidth  = window.innerWidth;
    let viewHeight = window.innerHeight;
    let { top, left, bottom, right } = this.$el.getBoundingClientRect();
    this.xOffset = right > viewWidth ? -Math.min(left, right - viewWidth) : 0;
    this.yOffset = bottom > viewHeight ? -Math.min(top, bottom - viewHeight) : 0;
  }
});
