import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, createBlock as _createBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1f8ec412"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "list-field-control" }
const _hoisted_2 = ["onPointerdown"]
const _hoisted_3 = ["onPointerdown"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Plus = _resolveComponent("Plus")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx._property.value, ([key, value]) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "field-item",
        key: key
      }, [
        (_ctx.getField(value.type) === 'DictionaryField')
          ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.getField(value.type)), {
              key: 0,
              property: value,
              onChange: _cache[0] || (_cache[0] = (...args) => _ctx.$emit('change', ...args)),
              onCreate: _cache[1] || (_cache[1] = (...args) => _ctx.$emit('create', ...args)),
              onDelete: _cache[2] || (_cache[2] = (...args) => _ctx.$emit('delete', ...args))
            }, {
              default: _withCtx(() => [
                (_ctx.isEditable)
                  ? (_openBlock(), _createElementBlock("button", {
                      key: 0,
                      class: "delete-button",
                      onPointerdown: ($event: any) => (_ctx.onDelete(key)),
                      tabindex: "-1"
                    }, "✗", 40, _hoisted_2))
                  : _createCommentVNode("", true)
              ]),
              _: 2
            }, 1064, ["property"]))
          : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
              (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.getField(value.type)), {
                property: value,
                onChange: _cache[3] || (_cache[3] = (...args) => _ctx.$emit('change', ...args)),
                onCreate: _cache[4] || (_cache[4] = (...args) => _ctx.$emit('create', ...args)),
                onDelete: _cache[5] || (_cache[5] = (...args) => _ctx.$emit('delete', ...args))
              }, null, 40, ["property"])),
              (_ctx.isEditable)
                ? (_openBlock(), _createElementBlock("button", {
                    key: 0,
                    class: "delete-button",
                    onPointerdown: ($event: any) => (_ctx.onDelete(key)),
                    tabindex: "-1"
                  }, "✗", 40, _hoisted_3))
                : _createCommentVNode("", true)
            ], 64))
      ]))
    }), 128)),
    (_ctx.isEditable)
      ? (_openBlock(), _createElementBlock("button", {
          key: 0,
          class: "create-button",
          onPointerdown: _cache[6] || (_cache[6] = ($event: any) => (_ctx.onCreate()))
        }, [
          _createElementVNode("span", null, [
            _createVNode(_component_Plus)
          ]),
          _createTextVNode("Add ")
        ], 32))
      : _createCommentVNode("", true)
  ]))
}