import { vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, withModifiers as _withModifiers, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-cfce8e40"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "grid-container" }
const _hoisted_2 = { class: "placeholder" }
const _hoisted_3 = ["contenteditable"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['text-field-control', { disabled: !_ctx.isEditable }]),
    tabindex: "0",
    onFocus: _cache[4] || (_cache[4] = ($event: any) => (_ctx.onFocus()))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _withDirectives(_createElementVNode("p", _hoisted_2, " Null ", 512), [
        [_vShow, _ctx.showPlaceholder]
      ]),
      _createElementVNode("div", {
        ref: "field",
        class: "field",
        onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onInput && _ctx.onInput(...args))),
        onKeyup: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["stop"])),
        onKeydown: _cache[2] || (_cache[2] = _withModifiers(() => {}, ["stop"])),
        onBlur: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.onBlur && _ctx.onBlur(...args))),
        contenteditable: _ctx.isEditable
      }, _toDisplayString(this.value), 41, _hoisted_3)
    ])
  ], 34))
}