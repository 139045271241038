
import { defineComponent } from "vue";

export default defineComponent({
  name: "CollapseArrow",
  props: {
    collapsed: {
      type: Boolean,
      default: false 
    },
    color: {
      type: String,
      default: "#8c8c8c"
    }
  }
});
