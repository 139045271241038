
// Dependencies
import { defineComponent, PropType } from 'vue';
import { ContextMenu } from "@/assets/scripts/ContextMenuTypes";
// Components
import FocusBox from "@/components/Containers/FocusBox.vue";
import ContextMenuListing from "./ContextMenuListing.vue";

export default defineComponent({
  name: 'TitleBar',
  props: {
    menus: {
      type: Array as PropType<ContextMenu[]>,
      default: []
    }
  },
  data() {
    return {
      focusedMenu: null as string | null
    }
  },
  emits: ["select"],
  methods: {
    
    /**
     * Menu selection behavior.
     * @param id
     *  The id of the selected menu.
     */
    menuOpen(id: string) {
      this.focusedMenu = id;
    },

    /**
     * Menu mouse enter behavior.
     * @param id
     *  The id of the hovered menu.
     */
    menuEnter(id: string) {
      if(this.focusedMenu === null)
        return;
      this.focusedMenu = id;
    },

    /**
     * Menu close behavior.
     */
    menuClose() {
      this.focusedMenu = null;
    },

    /**
     * Menu item selection behavior.
     * @param data
     *  The menu item's data.
     * @param closeSubmenu
     *  If the active menu should close.

     */
    menuSelect(data: any, closeMenu: boolean) {
      this.$emit("select", data);
      if(closeMenu) this.focusedMenu = null;
    }

  },
  components: { FocusBox, ContextMenuListing }
});
