import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HotkeyBox = _resolveComponent("HotkeyBox")!

  return (_openBlock(), _createBlock(_component_HotkeyBox, {
    class: "app-hotkey-box-element",
    hotkeys: _ctx.hotkeys,
    global: true,
    onFire: _ctx.onHotkeyFired
  }, {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _: 3
  }, 8, ["hotkeys", "onFire"]))
}