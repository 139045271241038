
import * as Store from "@/store/StoreTypes";
// Dependencies
import { defineComponent } from "vue";
import { mapGetters, mapState } from "vuex";
import { DictionaryProperty, PageModel } from "@/assets/scripts/BlockDiagram";
// Components
import AccordionBox from "@/components/Containers/AccordionBox.vue";
import AccordionPane from "@/components/Containers/AccordionPane.vue";
import PropertyEditor from "@/components/Elements/PropertyEditor.vue";
import ValidatorProblems from "@/components/Elements/ValidatorProblems.vue";

export default defineComponent({
  name: "EditorSidebar",
  computed: {

    /**
     * Application Store data
     */
    ...mapState("ApplicationStore", {
      page(state: Store.ApplicationStore): PageModel {
        return state.activePage.page;
      },
    }),

    ...mapGetters("ApplicationStore", [
      "hasSelection", "getSelection"
    ]),

    /**
     * Returns the currently selected object's properties.
     * @returns
     *  The currently selected object's properties.
     */
    selected(): DictionaryProperty | undefined {
      if(this.hasSelection === 0) {
        return this.page.props;
      } else if(this.hasSelection === 1) {
        return this.getSelection[0].props;
      }
      return undefined;
    }

  },
  components: { 
    AccordionBox,
    AccordionPane,
    PropertyEditor,
    ValidatorProblems
  }
});
