import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5181abbf"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "dictionary-field-contents-control" }
const _hoisted_2 = { class: "field-name" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.fields, ([key, value]) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "field-item",
        key: key
      }, [
        _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.titleCase(key)), 1),
        (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.getField(value.type)), {
          class: "field-value",
          property: value,
          onChange: _cache[0] || (_cache[0] = (...args) => _ctx.$emit('change', ...args)),
          onCreate: _cache[1] || (_cache[1] = (...args) => _ctx.$emit('create', ...args)),
          onDelete: _cache[2] || (_cache[2] = (...args) => _ctx.$emit('delete', ...args))
        }, null, 40, ["property"]))
      ]))
    }), 128))
  ]))
}