import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, vShow as _vShow, normalizeStyle as _normalizeStyle, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-cf54a08c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "scrollbox-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      ref: "content",
      class: "scroll-content",
      onWheelPassive: _cache[0] || (_cache[0] = ($event: any) => (_ctx.moveScrollPosition(_ctx.scrollTop + $event.deltaY, $event)))
    }, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ], 544),
    _withDirectives(_createElementVNode("div", {
      class: "scroll-bar",
      style: _normalizeStyle(_ctx.scroll.sty),
      onWheelPassive: _cache[3] || (_cache[3] = ($event: any) => (_ctx.moveScrollPosition(_ctx.scrollTop + $event.deltaY, $event)))
    }, [
      _withDirectives(_createElementVNode("div", {
        class: "scroll-handle",
        style: _normalizeStyle(_ctx.handle.sty),
        onPointerdown: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.startDrag && _ctx.startDrag(...args))),
        onPointerup: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.stopDrag && _ctx.stopDrag(...args)))
      }, null, 36), [
        [_vShow, _ctx.showScrollbar]
      ])
    ], 36), [
      [_vShow, _ctx.alwaysShowScrollBar || _ctx.showScrollbar]
    ])
  ]))
}