import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementBlock as _createElementBlock, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5d47093b"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "object-editor-element" }
const _hoisted_2 = {
  key: 1,
  class: "no-prop"
}
const _hoisted_3 = {
  key: 1,
  class: "no-prop"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DictionaryFieldContents = _resolveComponent("DictionaryFieldContents")!
  const _component_ScrollBox = _resolveComponent("ScrollBox")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.property)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          (_ctx.hasEditableProperties)
            ? (_openBlock(), _createBlock(_component_ScrollBox, {
                key: 0,
                alwaysShowScrollBar: true,
                scrollColor: "#1f1f1f"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_DictionaryFieldContents, {
                    class: "contents",
                    property: _ctx.property,
                    onChange: _ctx.onChange,
                    onCreate: _ctx.onCreate,
                    onDelete: _ctx.onDelete
                  }, null, 8, ["property", "onChange", "onCreate", "onDelete"])
                ]),
                _: 1
              }))
            : (_openBlock(), _createElementBlock("p", _hoisted_2, [
                _renderSlot(_ctx.$slots, "no-props", {}, undefined, true)
              ]))
        ], 64))
      : (_openBlock(), _createElementBlock("p", _hoisted_3, [
          _renderSlot(_ctx.$slots, "no-prop", {}, undefined, true)
        ]))
  ]))
}